.hpt__component {
    color: white;
    display: flex;
    flex-direction: row;
    margin: 0 2em;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: fit-content;

    & h2 {
        color: #C19BFDFF;
        margin: 0;
        padding: .5em;
    }

    .hpt__header {
        width: 100%;
    }

    @media (max-width: 768px) {
        max-width: 100%;
    }
}

.hpt__record {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: .25em 0;
    padding: .15em 0;
    text-align: left;
    width: 100%;
}

.hpt__record--table-header {
    font-weight: 600;
    color: #66d9ff;
    border-top: 1px solid #66aaff;
    padding: .5em 0
}

.hpt__slide {
    width: 20%;
    margin: 0;
    padding: 0 0 .5em 0;
    border-bottom: 1px solid #66aaff;
    line-height: 1em;
    font-size: 12pt;
}

@media (max-width: 768px) {
    .hpt__record {
        border-bottom: 1px solid #66aaff;
        border-radius: 25px;
        padding: 1em;
        margin: 0;
        width: 100%;
    }

    .hpt__slide {
        width: 100%;
        margin: 0;
        padding: 0;
        border: none;
        line-height: 1.5em;
        font-size: 10pt;
    }
}
