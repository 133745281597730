.AboutMe {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.AboutSite {
    width: 100%;
}

.AboutMe__intro {
    margin: 1em;
    text-align: justify;

    & span {
        color:#0edbee;
        font-size: 16pt;
        padding: 0 .25em;
    }
    & ul {
        list-style: none;
        padding-inline-start: 0;
    }
}

.AboutMe__component {
    background-color: transparent;
    height: fit-content;
    width: 100%;

    & h1 {
        color: #1b98bb;
    }
}

.AboutMe__container {
    align-items: center;
    background-color: transparent;
    color: #ffeedd;
    display: flex;
    flex-direction: column;
    height: 30vh;
    justify-content: flex-start;
    padding-top: 2rem;
    text-align: center;
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: 768px) {
        margin-bottom: 1.5em;
        padding-top: 0;
    }
}

.AboutMe__container::after {
    position: absolute;
    border: #dd77ff solid 3px;
    border-radius: 50%;
    content: "";
    height: 150px;
    margin-right: 0;
    margin-top: 20%;
    opacity: .33;
    width: 150px;
    z-index: 1;
}

@media (prefers-reduced-motion: no-preference) {
    .AboutMe__container::after {
        animation: oval-spin-2 infinite 5s linear;
    }
}

.AboutMe::after {
    border: #00ffcc solid 5px;
    border-radius: 50%;
    content: "";
    height: 150px;
    margin-right: 50%;
    margin-top: -15vh;
    opacity: .33;
    width: 150px;
    z-index: 1;
}

@media (prefers-reduced-motion: no-preference) {
    .AboutMe::after {
        animation: oval-spin3 infinite 16s linear;
    }
}

.itsMe {
    width: 200px;
    height: 250px;
    background: linear-gradient(to right, #4397ec, #22f211) no-repeat center;
    padding: 3px;
    border-radius: 50%;
    margin: 0;
    z-index: 2;
}

/* keyframes for animations */

/* Bounce effect for oval */
@keyframes oval-spin3 {
    0% {
        border: #00ffcc solid 5px;
        border-radius: 50%;
        margin-right: 50%;
        margin-top: -15vh;
        transform: rotate3d(0, 1, 1, 0deg);
    }
    12.5% {
        border: #dd77ff solid 7px;
        border-radius: 25%;
        margin-right: -50%;
        margin-top: 0;
        transform: rotate3d(0, 1, 1, 90deg);
    }
    25% {
        border: #ff00ff solid 5px;
        border-radius: 50%;
        margin-right: -35%;
        margin-top: 20vh;
        transform: rotate3d(0, 1, 1, 180deg);
    }
    37.5% {
        border: #dd77ff solid 3px;
        border-radius: 0;
        margin-right: 25%;
        margin-top: -17vh;
        transform: rotate3d(0, 1, 1, 360deg);
    }
    50% {
        border: #00ffcc solid 5px;
        border-radius: 50%;
        margin-right: 55%;
        margin-top: 24vh;
        transform: rotate3d(0, 1, 1, 540deg);
    }
    62.5% {
        border: #5b03cb solid 7px;
        border-radius: 5%;
        margin-right: -5%;
        margin-top: -32vh;
        transform: rotate3d(0, 1, 1, 720deg);
    }
    75% {
        border: #ff00ff solid 5px;
        border-radius: 50%;
        margin-right: 45%;
        margin-top: 12vh;
        transform: rotate3d(0, 1, 1, 630deg);
    }
    87.5% {
        border: #0edbee solid 3px;
        border-radius: 0;
        margin-right: -50%;
        margin-top: -31vh;
        transform: rotate3d(0, 1, 1, 360deg);
    }
    100% {
        border: #00ffcc solid 5px;
        border-radius: 50%;
        margin-right: 50%;
        margin-top: -15vh;
        transform: rotate3d(0, 1, 1, 0deg);
    }

}

@keyframes oval-spin-2 {
    0% {
        border-color: #dd77ff;
        border-width: 3px;
        margin-right: 0;
        margin-top: 20%;
        transform: rotate3d(0, 1, 1, 0deg);
    }
    25% {
        border-width: 5px;
        margin-right: -50%;
        margin-top: 10%;
        transform: rotate3d(0, 1, 1, 180deg);
    }
    50% {
        border-color: #77eecc;
        border-width: 7px;
        margin-right: 0;
        margin-top: 0;
        transform: rotate3d(0, 1, 1, 360deg);
    }
    75% {
        border-width: 5px;
        margin-right: 50%;
        margin-top: 10%;
        transform: rotate3d(0, 1, 1, 540deg);
    }
    100% {
        border-color: #dd77ff;
        border-width: 3px;
        margin-right: 0;
        margin-top: 20%;
        transform: rotate3d(0, 1, 1, 720deg);
    }
}
