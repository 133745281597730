/* Styling for myResume component */
.MyResume__component {
    text-align: left;
    background-color: #cfcfcf;
    color: #214321;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 auto;
    width: 90%;
    height: 100%;
    padding-bottom: 1em;

    & h1 {
        margin-bottom: 0;
        width: fit-content;
    }
    & h2 {
        color: #3b259d;
        font-size: 16pt;
    }
    & h3 {
        color: #2a1b57;
    }

    @media (max-width: 768px) {
        & h1 {
            margin: 0;
            width: 100%;
        }
    }
}

.MyResume__header {
    width: 100%;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 1em 1em 1em;

    & h3 {
        margin-top: 1.75em;
        margin-left: 1em;
        margin-bottom: 0;

        @media (max-width: 768px) {
            margin: 0;
        }
    }
}

.MyResume__section-selector {
    background-color: #fdfdfd;
    border-bottom: 2px solid #1b98bb;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 1em 0;

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.MyResume__section-selector--sticky {
    position: sticky;
    top: 0;
    background-color: #fdfdfd;
    border-bottom: 2px solid #1b98bb;
    z-index: 1;
}

.MyResume__section {
    color: #3b254d;
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    padding: 0.5em 1em;
    width: 20%;

    @media (max-width: 768px) {
        width: 20%;
    }
}

.MyResume__content {
    padding: 1em;
}

.MyResume__section--selected{
    color: #c404c1;
    text-decoration: #1b98bb underline 2px;
}

.MyResume__introduction {
    margin: 0 1em;
    text-align: justify;
    max-width: 30%;
    & h2 {
        margin-top: 0;
    }
    
    @media (max-width: 1260px) {
        max-width: 70%;
    }
    @media (max-width: 1080px) {
        max-width: 65%;
    }
    @media (max-width: 768px) {
        margin: 0;
        max-width: 100%;
    }
}

.MyResume__itsMe {
    width: 150px;
    margin: 1em 1em 0 1em;
    height: auto;
    border: 2px solid #1b98bb;
    border-radius: 10%;

    @media (max-width: 768px) {
        width: 40%;
        margin: 0 auto;
    }
}

.MyResume__contact {
    background-color: #112233;
    border: 2px solid #1b98bb;
    border-radius: 30px;
    color: #fdfdfd;
    margin: 1em;

    & h3 {
        color: #a5fef8;
        font-size: 1.3rem;
        font-weight: 700;
        margin: .5rem 0;
        padding: 0;
    }

    & p {
        font-size: .8rem;
        font-weight: 500;
        margin: .25rem 0;
        padding: 0;
    }

    & ul, & li {
        margin-bottom: .25rem;
        margin-top: .35rem;
    }

    & a {
        color: #a5fef8;
        letter-spacing: .055rem;
        text-decoration: underline #cdcdcd;
        text-decoration-thickness: 1px;
    }

    @media (max-width: 1260px) {
        width: 100%;
    }
}

.MyResume__cert-img {
    height: auto;
    max-width: 150px;
    padding-top: 1em;
    margin: 0 1em;
}

.MyResume__experience {
    width: 100%;
}

.MyResume__experience li {
    margin-bottom: 1em;
}
