/* Styles for the tabs component that hides & shows content based on selected tab. */
.Tabs__component {
    width: 100%;
}

.Tabs__container {
    background-color: #010101;
    border-bottom: 2px solid #61dafb;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.Tabs__tab {
    background-color: #444444;
    border-top: 1px solid #6666cc;
    border-left: 1px solid #6666cc;
    border-right: 1px solid #6666cc;
    border-radius: 7px 7px 0 0;
    color: #58f1d0;
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    padding: 0.5em 1em;
    width: 20%;

    @media (max-width: 768px) {
        width: 20%;
    }
}

.Tabs__tab--selected {
    background-color: #111221;
    border-top: 1px solid #61dafb;
    border-left: 1px solid #61dafb;
    border-right: 1px solid #61dafb;
    color: #00ffff;
}

.Tabs__content {
    display: none;
}

.Tabs__content--selected {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.Tabs__tab--disabled {
    background-color: #eee;
    color: #aaa;
    cursor: not-allowed;
}

/* Individual Tabs */
.Tabs__content--projects {
    padding: 1em;
}
