body {
  background-color: #2a2a2a;
  color: #deeedd;
}

h2 {
  color: #c19bfd;
}

h3 {
  color: #22ff66;
}

.App {
  text-align: center;
  height: 100%;
}

.App-header {
  background-color: #010101;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #c19bfd;
}

.App-header__logo {
  padding: 1em .25em 0 .25em;
}

.App-logo {
  border: 2px solid #61dafb;
  border-radius: 35%;
  margin: 0 1em .5em;
  max-height: 100px;
  pointer-events: none;

  @media (max-width: 768px) {
    max-height: 75px;
    margin-bottom: .75em;
  }
}

.App-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.App-title {
  color: #ff33ff;
  font-size: calc(10px + 3vmin);
  display: inline;
  margin: 0;
}
