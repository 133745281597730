/* Styles for DataGraph component */
.dataGraph__component {
  max-width: 1080px;
  margin: 0 auto;
  width: 87.5%;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
}

.dataGraph__timeframe {
  width: 10%;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.dataGraph__timeframe input {
  background-color: #332433;
  border: 2px solid #66aaff;
  border-radius: 10px;
  color: #66aaff;
  font-weight: 800;
  font-size: 16pt;
  height: 2em;
  padding: .5em;
  text-align: center;
  margin: 1em 1em;

  @media (max-width: 768px) {
    width: 30%;
    height: 1.5em;
  }
}